import { PropsFilterFnFactory } from '@ngrx/data';
import { ReadCompany } from '../authorization/generated';

export function sortByCode(x: ReadCompany, y: ReadCompany) {
    return x.code.localeCompare(y.code);
}

export function companyFilter(entities: ReadCompany[], pattern: string) {
    return PropsFilterFnFactory<ReadCompany>(['name', 'code'])(entities, pattern);
}
