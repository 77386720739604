import { EntityMetadata, EntityMetadataMap } from '@ngrx/data';
import { companyFilter, sortByCode } from '@app/user-manager/shared/api/model/company';
import { compareUsers, userFilter } from '@app/user-manager/shared/api/model/user';
import { comparePolicies, policyFilter } from './model/policy';
import { ReadCompany, ReadPolicy, ReadRole, ReadUser } from '@app/user-manager/shared/api/authorization/generated';
import { roleFilter, sortByName } from '@app/user-manager/shared/api/model/role';

export const entityMetadata: EntityMetadataMap = {
    ReadCompany: <EntityMetadata<ReadCompany>>{
        sortComparer: sortByCode,
        selectId: company => company.code,
        entityDispatcherOptions: {
            optimisticUpdate: true,
            optimisticDelete: true
        },
        filterFn: companyFilter
    },
    ReadUser: <EntityMetadata<ReadUser>>{
        sortComparer: compareUsers,
        selectId: user => user.id,
        entityDispatcherOptions: {
            optimisticUpdate: true,
            optimisticDelete: true
        },
        filterFn: userFilter
    },
    ReadRole: <EntityMetadata<ReadRole>>{
        sortComparer: sortByName,
        selectId: role => role.id,
        entityDispatcherOptions: {
            optimisticUpdate: true,
            optimisticDelete: true
        },
        filterFn: roleFilter
    },
    ReadPolicy: <EntityMetadata<ReadPolicy>>{
        sortComparer: comparePolicies,
        selectId: policy => policy.uri,
        entityDispatcherOptions: {
            optimisticUpdate: true
        },
        filterFn: policyFilter
    }
};

export function compareString(x: string, y: string) {
    if (x && y && typeof x === 'string' && typeof y === 'string') {
        return x.localeCompare(y);
    }
    return 0;
}

export function stringFilter(entities: string[], pattern: string) {
    return entities.filter(e => e.indexOf(pattern) >= 0);
}
