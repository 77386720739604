import { PropsFilterFnFactory } from '@ngrx/data';
import { ReadUser } from '../authorization/generated';

export function compareUsers(x: ReadUser, y: ReadUser) {
    return x.username.localeCompare(y.username);
}

export function userFilter(entities: ReadUser[], pattern: string) {
    return PropsFilterFnFactory<ReadUser>(['username', 'firstName', 'lastName'])(entities, pattern);
}
