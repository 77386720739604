import { PropsFilterFnFactory } from '@ngrx/data';
import { ReadPolicy } from '../authorization/generated';

export function comparePolicies(x: ReadPolicy, y: ReadPolicy) {
    return x.uri.localeCompare(y.uri);
}

export function policyFilter(entities: ReadPolicy[], pattern: string) {
    return PropsFilterFnFactory<ReadPolicy>(['uri'])(entities, pattern);
}
